<template>
  <div>
    <x-table
      title="证书列表"
      :card="true"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
      <template slot="form-configs-keystoreBase64" slot-scope="{row}">
        <el-upload
          class="upload-demo"
          :action="action"
          :headers="headers"
          :show-file-list="false"
          :limit="1"
          :on-success="(response) => keystoreSuccess(response, row)">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">点击上传</b-button>
        </el-upload>
        <b-form-textarea
          class="mt-1"
          v-model="row.configs.keystoreBase64"
        />
      </template>
      <template slot="form-configs-trustKeyStoreBase64" slot-scope="{row}">
        <el-upload
          class="upload-demo"
          :action="action"
          :headers="headers"
          :show-file-list="false"
          :limit="1"
          :on-success="(response, file) => trustKeySuccess(response, file, row)">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">点击上传</b-button>
        </el-upload>
        <b-form-textarea
          class="mt-1"
          v-model="row.configs.trustKeyStoreBase64"
        />
      </template>
    </x-table>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  query, add, edit, delById,
} from '@/api/device-access/certificate-list'
import {
  BFormTextarea, BButton,
} from 'bootstrap-vue'
import { getToken } from '@/@core/auth/token'

export default {
  components: {
    XTable,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      content: ['aaaa', 'bbbb', 'cccc'],
      // 上传请求地址
      action: this.$xapi.CA_UPLOAD,
      // 上传请求头部信息
      headers: {
        'X-Access-Token': getToken(),
      },
      fileList: [],
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        searchFold: true,
        lableVertical: true,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'view', name: '查看', icon: 'EyeIcon' },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          // { action: 'disable', name: '禁用', icon: 'SlashIcon' },
        ],
        actionFilter: action => action === 'view' || action === 'edit' || action === 'delete',
        columns: [{
          label: '名称',
          labelShow: true,
          prop: 'name',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入证书名称',
          },
        }, {
          label: '类型',
          labelShow: true,
          prop: 'instance',
          searchShow: true,
          rowSelect: true,
          type: 'select',
          dictData: [
            {
              id: 'PFX',
              name: 'PFX',
            },
            {
              id: 'JKS',
              name: 'JKS',
            },
            {
              id: 'PEM',
              name: 'PEM',
            },
          ],
          onDictSelected: (option, column) => {
            if (option === 'PEM') {
              column[2].children[1].addShow = false
              column[2].children[1].editShow = false
              column[2].children[3].addShow = false
              column[2].children[3].editShow = false
            } else if (option !== 'PEM' && option !== null) {
              column[2].children[1].addShow = true
              column[2].children[1].editShow = true
              column[2].children[3].addShow = true
              column[2].children[3].editShow = true
            }
          },
          props: { label: 'name', value: 'id' },
          selectVariant: prop => {
            if (prop === 'JKS') {
              return 'light-primary'
            }
            if (prop === 'PEM') {
              return 'light-warning'
            }
            return 'light-success'
          },
          rules: {
            rule: 'required',
            message: '选择证书类型',
          },
        }, {
          label: '配置',
          labelShow: true,
          rowShow: false,
          prop: 'configs',
          type: 'textarea',
          rows: 5,
          searchShow: false,
          children: [
            {
              label: '密钥库',
              labelShow: true,
              rowShow: false,
              prop: 'keystoreBase64',
              type: 'textarea',
              rows: 5,
              searchShow: false,
            }, {
              label: '密钥库密码',
              labelShow: true,
              rowShow: false,
              addShow: false,
              editShow: false,
              prop: 'keystorePwd',
              rows: 5,
              searchShow: false,
            }, {
              label: '信任库',
              labelShow: true,
              prop: 'trustKeyStoreBase64',
              type: 'textarea',
              rows: 5,
              searchShow: false,
              rules: {
                rule: 'required',
                message: '请输入信任库',
              },
            }, {
              label: '信任库密码',
              labelShow: true,
              rowShow: false,
              addShow: false,
              prop: 'trustKeyStorePwd',
              rows: 5,
              searchShow: false,
            },
          ],
        }, {
          label: '描述',
          labelShow: true,
          prop: 'description',
          type: 'textarea',
          rows: 5,
          searchShow: false,
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      delById(data.id).then(() => {
        done()
      })
    },
    addHandle(formData, done) {
      add(formData).then(() => {
        done()
      })
    },
    keystoreSuccess(response, row) {
      this.$xtoast.success('上传成功')
      if (response.code === 'success') {
        row.configs.keystoreBase64 = response.result
      }
    },
    trustKeySuccess(response, file, row) {
      this.$xtoast.success('上传成功')
      if (response.code === 'success') {
        row.configs.trustKeyStoreBase64 = response.result
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
