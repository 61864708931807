import request from '@/auth/jwt/useJwt'

/*  分页查询组件列表  */
export function query(pageIndex, pageSize, params) {
  let requestUrl = `network/certificate/_query?pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (params !== null) {
    if (params.name !== undefined && params.name !== '') {
      requestUrl = `network/certificate/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=name&terms[0].value=${params.name}`
    }
    if (params.instance !== undefined && params.instance !== null) {
      requestUrl = `network/certificate/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=instance&terms[0].value=${params.instance}`
    }
    if (params.name !== undefined && params.instance !== undefined && params.name !== '' && params.instance !== null) {
      requestUrl = `network/certificate/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=name&terms[0].value=${params.name}&terms[1].column=instance&terms[1].value=${params.instance}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/*  新增证书  */
export function add(data) {
  return request.axiosIns({
    url: 'network/certificate/',
    method: 'PATCH',
    data,
  })
}
/* 编辑证书 */
export function edit(data) {
  return request.axiosIns({
    url: 'network/certificate/',
    method: 'PATCH',
    data,
  })
}
/* 删除证书 */
export function delById(id) {
  return request.axiosIns({
    url: `network/certificate/${id}`,
    method: 'DELETE',
  })
}
